﻿const { Swiper } = require('swiper');

const brandsCarousel = function (el) {
	const swiper = new Swiper(el, {
		loop: true,
		speed: 400,
		spaceBetween: 10,
		slidesPerView: 2,
		breakpoints: {
			// when window width is >= 640px
			766: {
				slidesPerView: 3
			},
			// when window width is >= 640px
			991: {
				slidesPerView: 6
			}
		}
	});
};

module.exports = brandsCarousel;