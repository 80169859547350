"use strict";
const popupCart = (function () {
  const loadCartUrl = "index.php?route=common/popupcart";

  const loadCart = function () {
    $("body").append(`
			<div class="modal fade" id="cartModal" tabindex="-1" role="dialog" aria-hidden="true">
				<div class="modal-dialog modal-lg" role="document">
					<div class="modal-content">			
					</div>
				</div>
			</div>
		`);

    $(document).find("#cartModal .modal-content").load(loadCartUrl);
  };

  const openModal = () => {
    const $modal = $(document).find("#cartModal");

    $modal.modal("show");
  };

  const common = function () {
    const $modal = $(document).find("#cartModal");
    const $headerCart = $(document).find(".header-cart");

    const getProductKey = (that) =>
      that.closest(".product-row").data("productKey");
    const getProductQty = (that) =>
      +that.closest(".product-row").find(".quantity-input").val();
    const getProductQuantity = (that) =>
      +that.closest(".product-row").find("#input-quantity").val();

    $modal.on("shown.bs.modal", function () {
      $modal.find(".modal-content").load(loadCartUrl);
    });

    const refreshCart = () => {
      const $target = $modal.find(".modal-content");

      $target.addClass("loading");
      $target.load(loadCartUrl, function (e) {
        $headerCart.load("index.php?route=common/cart/info");
        $target.removeClass("loading");
      });
    };

    const cart = {
      add: (product_id, quantity) =>
        new Promise((resolve) => {
          return $.ajax({
            url: "index.php?route=checkout/cart/add",
            type: "post",
            data: {
              product_id,
              quantity,
            },
            dataType: "json",
            success: (json) => {
              resolve(json);
              refreshCart();
            },
          });
        }),

      remove: (key) => {
        $.ajax({
          url: "index.php?route=checkout/cart/remove",
          type: "post",
          data: `key=${key}`,
          dataType: "json",
          success: () => {
            refreshCart();
          },
        });
      },

      update: (key, qty) => {
        $.ajax({
          type: "post",
          data: { quantity: qty, key },
          url: "index.php?route=checkout/cart/edit",
          dataType: "json",
          success: () => {
            refreshCart();
          },
        });
      },
    };

    $(document).on("click", ".btn-add-cart-popup", function (e) {
      e.preventDefault();
      const $productCard = $(this).closest(".product-thumb");
      const productId = $(this).data("productId");

      $productCard.addClass("loading");
      cart.add(productId, 1).then(() => {
        openModal();
        $productCard.removeClass("loading");
      });
    });

    $(document).on("click", ".quantity-plus", function (e) {
      e.preventDefault();
      const key = getProductKey($(this));
      const qty = getProductQty($(this));

      cart.update(key, qty + 1);
    });

    $(document).on("click", ".quantity-minus", function (e) {
      e.preventDefault();
      const key = getProductKey($(this));
      const qty = getProductQty($(this));

      cart.update(key, qty - 1);
    });

    $(document).on("click", ".btn-remove-product", function (e) {
      e.preventDefault();
      const key = getProductKey($(this));

      cart.remove(key);
    });

    $(document).on("change", ".quantity-input", function (e) {
      e.preventDefault();
      const key = getProductKey($(this));
      const qty = $(this).val();

      cart.update(key, +qty);
    });

    $headerCart.on("click", ">a", function (e) {
      e.preventDefault();
      openModal();
    });
  };

  return {
    init: function () {
      loadCart();
      common();
    },
    showCart: openModal,
  };
})();

module.exports = popupCart;
