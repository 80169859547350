"use strict";
const Noty = require("noty");

const Notification = (function () {

  const init = function () {
    Noty.overrideDefaults({
      theme: "bootstrap-v4",
      timeout: 3000,
      animation: {
        open: "animated bounceInRight", // Animate.css class names
        close: "animated bounceOutRight", // Animate.css class names
      },
    });
  };

  function handleMessage(text, type) {
    new Noty({
      theme: "bootstrap-v4",
      timeout: 3000,
      speed: 300,
      type,
      text,
      closeWith: ["click", "button"],
      buttons: true,
    }).show();
  }

  const errorMessage = (message) => {
    handleMessage(message, "error");
  };

  const successMessage = (message) => {
    handleMessage(message, "success");
  };

  return {
    init,
    success: (message) => successMessage(message),
    error: (message) => errorMessage(message),
  };
})();

module.exports = Notification;
