"use strict";

const liveSearch = (function () {
  let timer = null;

  const closeModal = () => {
    $(document).find(".gd-search-mod").removeClass("opened");
  };

  const openModal = () => {
    $(".search-container").removeClass("loading");
    $(".gd-search-list-items").empty();
    $(".gd-search-list-catalog").empty();
    $(".gd-search-catalog-count").empty();

    $(".gd-search-mod").addClass("opened");
  };

  const init = function () {
    const html = `<div class="gd-search-mod">
						<div class="gd-search-mod-container">
							<a class="gd-search-btn" data-toggle="collapse" data-target="#gd-search-catalog" aria-expanded="true">
								Категорії <span class="gd-search-catalog-count"></span>
							</a>
							<div class="gd-search-list">
								<div class="gd-search-list-catalog collapse" id="gd-search-catalog"></div>
								<div class="gd-search-list-items"></div>
							</div>
						</div>
					</div>`;
    $(".search-container").append(html);

    $(document).mouseup(function (e) {
      const container = $(document).find(".search-container");
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        closeModal();
      }
    });

    $(document).keyup(function (e) {
      if (e.keyCode === 27) {
        closeModal();
      }
    });

    $("#gd-search-lv").on("input", function () {
      if (timer) {
        clearTimeout(timer);
        timer = null;
      }

      timer = window.setTimeout(function () {
        searchAll();
      }, 300);
    });
  };

  const getProductTemplate = (value_prod) => {
    return `<div class="gd-search-item">
					<a href="${value_prod["href"]}" title="${value_prod["name"]}">
						<div class="gd-search-item-image"><img alt="${value_prod["name"]}" src="${value_prod["thumb"]}" /></div>
						<div class="gd-search-item-content">
							<div class="gd-search-item-name">${value_prod["name"]}</div>
							<div class="gd-search-item-price">${value_prod["price"]}</div>
						</div>
					</a>
				</div>`;
  };

  function searchAll() {
    $(".search-container").addClass("loading");

    const live_search_min_length = 1;
    const search_value = $("#gd-search-lv").val();
    const all_search_href = `index.php?route=product/lvsearch/lvsearch&search=${encodeURIComponent(
      search_value
    )}`;

    if (search_value.length <= live_search_min_length) {
      closeModal();
      $(".search-container").removeClass("loading");
      return;
    }

    $.ajax({
      url: all_search_href,
      dataType: "json",
      success: function (result) {
        openModal();

        const contt_all = result.count;
        const result_data = result.result_data;
        const result_data_home = result.result_data_home;

        $(".gd-search-list-items").empty();
        $(".gd-search-list-catalog").empty();
        $(".gd-search-catalog-count").empty();

        if (!$.isEmptyObject(result_data)) {
          $(".gd-search-catalog-count").append(result_data.length);

          let html_catalog = `<button onclick="searchAll();"><span class="search_cat_count">${contt_all}</span></button>`;
          $.each(result_data, function (index, value) {
            const cat_name = value["cat_name"];
            html_catalog += `<button onclick="searchInCategory(${value["cat_id"]}, '${search_value}', '${cat_name}');">
											${cat_name} 
											<span class="search_cat_count">${value["cat_count"]}</span>
										</button>`;
          });

          $(".gd-search-list-catalog").append(html_catalog);

          $.each(result_data_home, function (index, value) {
            const cat_name = value["cat_name"];
            const html_cat = `<div class="gd-search-list-grid gd-search-list_cat_${value["cat_id"]}">
											<div class="gd-search-cat-name">
												${cat_name}
											</div>
											<div class="gd-search-cat-products"></div>
											<button class="gd-search-cat-count" onclick="searchInCategory(${value["cat_id"]}, '${search_value}', '${cat_name}');">Показати всі (${value["cat_count"]})</button>
										</div>`;

            $(".gd-search-list-items").append(html_cat);

            $.each(value["products"], function (ind, value_prod) {
              const html_prod = getProductTemplate(value_prod);

              $(`.gd-search-list_cat_${value["cat_id"]}`)
                .find(".gd-search-cat-products")
                .append(html_prod);
            });
          });
        } else {
          $(".gd-search-list-items").html("Не знайдено жодного продукту");

          closeModal();
        }

        return false;
      },
      error: function (response) {
        openModal();
        $(".gd-search-list-items").html("Не знайдено жодного продукту");
      },
    });
  }

  function searchInCategory(cat_id, search_value, cat_name) {
    const cat_search_href = `index.php?route=product/lvsearch/searchlvcategori&catt_id=${cat_id}&search=${encodeURIComponent(
      search_value
    )}`;

    $.ajax({
      url: cat_search_href,
      dataType: "json",
      success: function (result) {
        const cat_prods = result;
        $(".gd-search-list-items").empty();
        $(".gd-search-list-items").append(
          `<div class="gd-search-cat-name"><span>${cat_name}</span></div>`
        );
        $(".gd-search-list-items").append(
          `<div class="gd-search-cat-products"></div>`
        );

        $.each(cat_prods, function (ind, value_prod) {
          const html_cat_prod = getProductTemplate(value_prod);
          $(".gd-search-list-items")
            .find(".gd-search-cat-products")
            .append(html_cat_prod);
        });
      },
    });
  }

  return {
    init,
    searchInCategory,
  };
})();

module.exports = liveSearch;
