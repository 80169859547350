﻿const { Swiper } = require('swiper');
const { Navigation, Autoplay } = require('swiper/modules');

const ProductCarousel = function (el) {
	Swiper.use([Navigation, Autoplay]);

	const swiper = new Swiper(el, {
		loop: true,
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
		autoplay: {
			delay: 12000
		},
		speed: 800,
		slidesPerView: 1,
		spaceBetween: 10,
		breakpoints: {
			// when window width is >= 640px
			640: {
				slidesPerView: 2
			},
			// when window width is >= 1100
			1100: {
				slidesPerView: 4
			}
		}
	});
};

module.exports = ProductCarousel;