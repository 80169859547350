﻿const { Swiper } = require('swiper');
const { Navigation, Pagination, Autoplay } = require('swiper/modules');

const mainSlider = function (el) {
	// configure Swiper to use modules
	Swiper.use([Navigation, Pagination, Autoplay]);

	const swiper = new Swiper(el, {
		autoplay: {
			delay: 9000,
		},
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
		pagination: {
			el: ".slideshow0",
			clickable: true,
		},
		effect: 'slide',
		autoplayDisableOnInteraction: true,
		loop: true
	});

};

module.exports = mainSlider;