"use strict";
require('select2');
require('select2/dist/js/i18n/uk');

const checkout = (function () {
  const main = function () {
    const goToPayemnt = (paymentCode) => {
      $.ajax({
        url: `index.php?route=extension/payment/${paymentCode}`,
        type: "get",
        data: $("#form-checkout").serialize(),
        contentType: "application/x-www-form-urlencoded",
        beforeSend: function () {
          $("#button-checkout").button("loading");
        },
        complete: function () {
          $("#button-checkout").button("reset");
        },
        success: function (html) {
          console.log(html);
        },
        error: function (xhr, ajaxOptions, thrownError) {
          console.log(
            thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText
          );
        },
      });
    };

    $("#form-checkout").on("submit", function (e) {
      e.preventDefault();

      $.ajax({
        //url: 'index.php?route=checkout/checkout_new/saveOrder',
        url: "index.php?route=checkout/onepage/confirm/validOrder",
        type: "post",
        dataType: "json",
        data: $("#form-checkout").serialize(),
        contentType: "application/x-www-form-urlencoded",
        beforeSend: function () {
          $("#button-checkout").button("loading");
        },
        complete: function () {
          $("#button-checkout").button("reset");
        },
        success: function (json) {
          console.log(json);

          $("#form-checkout").find(".is-invalid").removeClass("is-invalid");
          $("#form-checkout").find(".invalid-feedback").removeClass("d-block");

          if (json["redirect"]) {
            location = json["redirect"];
          }

          if (json["error"]) {
            if (json["error"]["warning"]) {
              $("#alert").prepend(
                '<div class="alert alert-danger alert-dismissible"><i class="fa-solid fa-circle-exclamation"></i> ' +
                  json["error"]["warning"] +
                  ' <button type="button" class="btn-close" data-bs-dismiss="alert"></button></div>'
              );
            }

            for (const [key] of Object.entries(json["error"])) {
              $("#input-" + key.replaceAll("_", "-"))
                .addClass("is-invalid")
                .find(
                  ".form-control, .form-select, .form-check-input, .form-check-label"
                )
                .addClass("is-invalid");
              $("#error-" + key.replaceAll("_", "-"))
                .html(json["error"][key])
                .addClass("d-block");
            }
          }

          if (json["success"]) {
            $("#alert").prepend(
              '<div class="alert alert-success alert-dismissible"><i class="fa-solid fa-circle-check"></i> ' +
                json["success"] +
                ' <button type="button" class="btn-close" data-bs-dismiss="alert"></button></div>'
            );

            if ($("#input-register").prop("checked")) {
              $("input[name='account']").prop("disabled", true);
              $("#input-customer-group").prop("disabled", true);
              $("#input-password").prop("disabled", true);
              $("#input-captcha").prop("disabled", true);
              $("#input-register-agree").prop("disabled", true);
            }

            $("#input-shipping-method").val("");
            $("#input-payment-method").val("");

            $("#checkout-confirm").load(
              "index.php?route=checkout/confirm/confirm"
            );
          }
        },
        error: function (xhr, ajaxOptions, thrownError) {
          console.log(
            thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText
          );
        },
      });
    });
  };

  const register = function () {
    $("input[name=account]").on("click", function () {
      if ($(this).val() == 1) {
        $("#password").removeClass("d-none");
      } else {
        $("#password").addClass("d-none");
      }

      if ($(this).val() == 1) {
        $("#register-agree").removeClass("d-none");
      } else {
        $("#register-agree").addClass("d-none");
      }
    });
  };

  const shippingMethod = function () {
    let cityRef = "";
    $("#select-shipping-np-wh").prop("disabled", true);

    $("#select-shipping-method").select2({
      minimumResultsForSearch: -1,
    });

    $("#select-shipping-np-city")
      .select2({
        ajax: {
          url: "https://api.novaposhta.ua/v2.0/json/",
          type: "POST",
          dataType: "json",
          data: function (params) {
            var query = {
              apiKey: "85d7b1a274c517351a9150b96305f9ec",
              modelName: "Address",
              calledMethod: "searchSettlements",
              methodProperties: {
                CityName: params.term,
                Limit: 30,
                Language: "UA",
              },
            };
            return JSON.stringify(query);
          },
          processResults: function (json) {
            return {
              results: json.data[0]
                ? json.data[0].Addresses.map((x) => ({
                    id: x.DeliveryCity,
                    area: x.Area,
                    text: x.Present,
                  }))
                : [],
            };
          },
        },
      })
      .on("select2:select", function (e) {
        $("#input-shipping-np-city").val(e.params.data.text);
        cityRef = e.params.data.id;
        $("#select-shipping-np-wh").prop("disabled", false);
      });

    $("#select-shipping-np-wh")
      .select2({
        language: "uk",
        ajax: {
          url: "https://api.novaposhta.ua/v2.0/json/",
          type: "POST",
          dataType: "json",
          data: function (params) {
            const query = {
              apiKey: "85d7b1a274c517351a9150b96305f9ec",
              modelName: "Address",
              calledMethod: "getWarehouses",
              methodProperties: {
                FindByString: params.term,
                CityRef: cityRef,
                Limit: 50,
                Language: "UA",
              },
            };

            return JSON.stringify(query);
          },
          processResults: function (result) {
            return {
              results: result.data.map((x) => ({
                id: x.Ref,
                text: x.Description,
              })),
            };
          },
        },
      })
      .on("select2:select", function (e) {
        $("#input-shipping-np-wh").val(e.params.data.text);
      });

    $("input[name=shipping_existing]").on("change", function () {
      if ($(this).val() == 1) {
        $("#shipping-existing").show();
        $("#shipping-new").hide();
      } else {
        $("#shipping-existing").hide();
        $("#shipping-new").show();
      }
    });

    //checkout-shipping-address
    $("#select-shipping-method").on("change", function (e) {
      const method = $(this).val();

      if (method === "nova_poshta") {
        $("#novaposhta-address").removeClass("d-none");
      } else {
        $("#novaposhta-address").addClass("d-none");
      }
    });
  };

  const paymentMethod = function () {
    /* $('#input-checkout-agree').on('change', function () {
			$.ajax({
				url: 'index.php?route=checkout/payment_method.agree',
				type: 'post',
				data: $('#input-checkout-agree').serialize(),
				dataType: 'json',
				contentType: 'application/x-www-form-urlencoded',
				beforeSend: function () {
					$('#button-confirm').button('loading');
				},
				complete: function () {
					$('#button-confirm').button('reset');
				},
				success: function (json) {
					$('#checkout-confirm').load('index.php?route=checkout/confirm.confirm');
				},
				error: function (xhr, ajaxOptions, thrownError) {
					console.log(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
				}
			});
		}); */
  };

  $(document).delegate("#button-payment-method", "click", function () {
    $.ajax({
      url: "index.php?route=checkout/payment_method/save",
      type: "post",
      data: $(
        "#collapse-payment-method input[type='radio']:checked, #collapse-payment-method input[type='checkbox']:checked, #collapse-payment-method textarea"
      ),
      dataType: "json",
      beforeSend: function () {
        $("#button-payment-method").button("loading");
      },
      success: function (json) {
        $(".alert-dismissible, .text-danger").remove();

        if (json["redirect"]) {
          location = json["redirect"];
        } else if (json["error"]) {
          $("#button-payment-method").button("reset");

          if (json["error"]["warning"]) {
            $("#collapse-payment-method .panel-body").prepend(
              '<div class="alert alert-danger alert-dismissible">' +
                json["error"]["warning"] +
                '<button type="button" class="close" data-dismiss="alert">&times;</button></div>'
            );
          }
        } else {
          $.ajax({
            url: "index.php?route=checkout/confirm",
            dataType: "html",
            complete: function () {
              $("#button-payment-method").button("reset");
            },
            success: function (html) {
              $("#collapse-checkout-confirm .panel-body").html(html);

              $("#collapse-checkout-confirm")
                .parent()
                .find(".panel-heading .panel-title")
                .html(
                  '<a href="#collapse-checkout-confirm" data-toggle="collapse" data-parent="#accordion" class="accordion-toggle">{{ text_checkout_confirm }} <i class="fa fa-caret-down"></i></a>'
                );

              $("a[href='#collapse-checkout-confirm']").trigger("click");
            },
            error: function (xhr, ajaxOptions, thrownError) {
              alert(
                thrownError +
                  "\r\n" +
                  xhr.statusText +
                  "\r\n" +
                  xhr.responseText
              );
            },
          });
        }
      },
      error: function (xhr, ajaxOptions, thrownError) {
        alert(
          thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText
        );
      },
    });
  });

  return {
    init: function () {
      main();
      register();
      shippingMethod();
      paymentMethod();
    },
  };
})();

module.exports = checkout;
