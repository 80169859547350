"use strict";
const notification = require("./notification");
const { Swiper } = require("swiper");
const { Navigation, Thumbs, Controller } = require("swiper/modules");

const product = (function () {
  const gallery = () => {
    Swiper.use([Navigation, Controller, Thumbs]);

    const galleryThumbs = new Swiper(".gallery-thumbs", {
      spaceBetween: 5,
      slidesPerView: 4,
      freeMode: true,
    });

    const galleryPreview = new Swiper(".gallery-preview", {
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: galleryThumbs,
      },
    });

    galleryPreview.controller.control = galleryThumbs;
  };

  const form = function () {
    $("#input-subscription").on("change", function (e) {
      var element = this;

      $(".subscription").addClass("d-none");

      $("#subscription-description-" + $(element).val()).removeClass("d-none");
    });

    $("#form-product").on("submit", function (e) {
      e.preventDefault();

      $.ajax({
        url: "index.php?route=checkout/cart/add&language=uk-ua",
        type: "post",
        data: $(this).serialize(),
        dataType: "json",
        contentType: "application/x-www-form-urlencoded",
        cache: false,
        processData: false,
        beforeSend: function () {
          $("#button-cart").button("loading");
        },
        complete: function () {
          $("#button-cart").button("reset");
        },
        success: function (json) {
          $("#form-product").find(".is-invalid").removeClass("is-invalid");
          $("#form-product").find(".invalid-feedback").removeClass("d-block");

          if (json["error"]) {
            for (key in json["error"]) {
              $("#input-" + key.replaceAll("_", "-"))
                .addClass("is-invalid")
                .find(
                  ".form-control, .form-select, .form-check-input, .form-check-label"
                )
                .addClass("is-invalid");
              $("#error-" + key.replaceAll("_", "-"))
                .html(json["error"][key])
                .addClass("d-block");
            }
          }

          if (json["success"]) {
            notification.success(json["success"]);
            popupCart.showCart();
          }
        },
        error: function (xhr, ajaxOptions, thrownError) {
          notification.error(
            thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText
          );
        },
      });
    });
  };

  const reviews = function () {
    $("#review").on("click", ".pagination a", function (e) {
      e.preventDefault();

      $("#review").load(this.href);
    });

    $("#form-review").on("submit", function (e) {
      e.preventDefault();
      const language = $("#form-review").data("language");
      const product_id = $("#form-review").data("productId");

      $.ajax({
        url: `index.php?route=product/review/write&language=${language}&product_id=${product_id}`,
        type: "post",
        data: $("#form-review").serialize(),
        dataType: "json",
        cache: false,
        contentType: "application/x-www-form-urlencoded",
        processData: false,
        beforeSend: function () {
          $("#button-review").button("loading");
        },
        complete: function () {
          $("#button-review").button("reset");
        },
        success: function (json) {
          $(".alert-dismissible").remove();
          $("#form-review").find(".is-invalid").removeClass("is-invalid");
          $("#form-review").find(".invalid-feedback").removeClass("d-block");

          if (json["error"]) {
            $.each(json["error"], function (key, val) {
              $("#input-" + key.replaceAll("_", "-"))
                .addClass("is-invalid")
                .find(
                  ".form-control, .form-select, .form-check-input, .form-check-label"
                )
                .addClass("is-invalid");
              $("#error-" + key.replaceAll("_", "-"))
                .html(json["error"][key])
                .addClass("d-block");
            });
          }

          if (json["success"]) {
            notification.success(json["success"]);

            $("#input-text").val("");
            $("#input-rating input[type='radio']").prop("checked", false);
          }
        },
        error: function (xhr, ajaxOptions, thrownError) {
          notification.error(
            thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText
          );
        },
      });
    });
  };

  return {
    init: function () {
      gallery();
      form();
      reviews();
    },
  };
})();

module.exports = product;
