"use strict";
require("@popperjs/core");
require("@fancyapps/fancybox");
require("jquery-mask-plugin");
const notification = require("./notification");

const Common = (function () {
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  const display = () => {
    $('[data-bs-toggle="tooltip"]').tooltip();
    $("table").wrap("<div class='table-container' />");

    $("[data-fancybox]").fancybox({
      loop: true,
      caption: function (instance, item) {
        return $(this).find(".caption").html();
      },
    });
  };

  const initNavi = function () {
    const menuToggle = document.getElementsByClassName("menu-toggle")[0];
    const menuClose = document.getElementsByClassName("menu-close")[0];
    const menuWrapper = document.getElementsByClassName("menu-wrapper")[0];
    const mainMenu = document.getElementsByClassName("main-menu")[0];
    const parentLi = mainMenu.querySelectorAll("li.dropdown > .child-toggle");

    const togglerMenu = () => {
      const body = document.body;

      menuToggle.classList.toggle("menu-opened");
      body.classList.toggle("menu-opened");
      menuWrapper.classList.toggle("menu-wrapper--opened");
    };

    menuToggle.addEventListener("click", togglerMenu);
    menuClose.addEventListener("click", togglerMenu);

    document.addEventListener("click", function (e) {
      if (
        !mainMenu.contains(e.target) &&
        !menuToggle.contains(e.target) &&
        menuWrapper.classList.contains("main-menu--opened")
      ) {
        togglerMenu();
      }
    });

    parentLi.forEach((item) => {
      item.addEventListener("click", function (e) {
        e.preventDefault();
        this.parentElement.classList.toggle("opened");
      });
    });

    $(document).mouseup(function (e) {
      const container = $(".main-menu");
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        $(".menu-opened").removeClass("menu-opened");
        $(".menu-toggle").removeClass("menu-opened");
        $(".menu-wrapper").removeClass("menu-wrapper--opened");
      }
    });
    $(document).keyup(function (e) {
      if (e.keyCode === 27) {
        $(".menu-opened").removeClass("menu-opened");
        $(".menu-toggle").removeClass("menu-opened");
        $(".menu-wrapper").removeClass("menu-wrapper--opened");
      }
    });

    ///
    const $scrollTarget = $(".header-container");

    let lastScrollTop = 0;
    const headerContainer = $(".header-container");
    const appendix = isMobile ? 0 : 40;
    const scrollHeight = appendix + headerContainer.outerHeight();

    $(window).on("scroll", function () {
      const st = $(this).scrollTop();

      if (st > scrollHeight) {
        $scrollTarget.addClass("sticky");
        if (st < lastScrollTop) {
          $scrollTarget.addClass("scroll-up");
        } else {
          $scrollTarget.removeClass("scroll-up");
        }
      } else {
        if ($scrollTarget.hasClass("scroll-up")) {
          if (st <= appendix) {
            $scrollTarget.removeClass("scroll-up");
            $scrollTarget.removeClass("sticky");
          }
        } else {
          $scrollTarget.removeClass("scroll-up");
          $scrollTarget.removeClass("sticky");
        }
      }

      lastScrollTop = st;
    });
  };

  var runGoTop = function () {
    $(window).on("scroll", function () {
      if ($(this).scrollTop() > 300) {
        $(".top-button").addClass("top-button--shown");
      } else {
        $(".top-button").removeClass("top-button--shown");
      }
    });

    $(".top-button").on("click", function (e) {
      e.preventDefault();
      $("html, body").animate({ scrollTop: 0 }, 250);
    });
  };

  const collapse = () => {
    $(document).on("click", ".hitarea", function (e) {
      $(this).closest("li").toggleClass("opened");
    });
  };

  return {
    isMobile,
    init: function () {
      display();
      initNavi();
      runGoTop();
      collapse();
    },
    forms: {},
    callback: {},
  };
})();

module.exports = Common;
