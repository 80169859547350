"use strict";
require("@popperjs/core");
require("bootstrap-daterangepicker");
const notification = require("./notification");

const Opencart = (function () {
  function getURLVar(key) {
    var value = [];

    var query = String(document.location).split("?");

    if (query[1]) {
      var part = query[1].split("&");

      for (i = 0; i < part.length; i++) {
        var data = part[i].split("=");

        if (data[0] && data[1]) {
          value[data[0]] = data[1];
        }
      }

      if (value[key]) {
        return value[key];
      } else {
        return "";
      }
    }
  }

  // Date
  var oc_datepicker = function () {
    $(this).daterangepicker(
      {
        singleDatePicker: true,
        autoApply: true,
        autoUpdateInput: false,
        locale: {
          format: "YYYY-MM-DD",
        },
      },
      function (start, end) {
        $(this.element).val(start.format("YYYY-MM-DD"));
      }
    );
  };

  // Time
  var oc_timepicker = function () {
    $(this)
      .daterangepicker(
        {
          singleDatePicker: true,
          datePicker: false,
          autoApply: true,
          autoUpdateInput: false,
          timePicker: true,
          timePicker24Hour: true,
          locale: {
            format: "HH:mm",
          },
        },
        function (start, end) {
          $(this.element).val(start.format("HH:mm"));
        }
      )
      .on("show.daterangepicker", function (ev, picker) {
        picker.container.find(".calendar-table").hide();
      });
  };

  // Date Time
  var oc_datetimepicker = function () {
    $(this).daterangepicker(
      {
        singleDatePicker: true,
        autoApply: true,
        autoUpdateInput: false,
        timePicker: true,
        timePicker24Hour: true,
        locale: {
          format: "YYYY-MM-DD HH:mm",
        },
      },
      function (start, end) {
        $(this.element).val(start.format("YYYY-MM-DD HH:mm"));
      }
    );
  };

  var oc_alert = function () {
    window.setTimeout(function () {
      $(".alert-dismissible").fadeTo(3000, 0, function () {
        $(this).remove();
      });
    }, 3000);
  };

  const init = function () {
    $(document).on("click", "button", function () {
      $(".tooltip").remove();
    });

    $(document).on("focus", ".date", oc_datepicker);
    $(document).on("focus", ".time", oc_timepicker);
    $(document).on("focus", ".datetime", oc_datetimepicker);

    $(document).on("click", "button", oc_alert);
    $(document).on("click", "change", oc_alert);

    // Currency
    $("#form-currency .dropdown-item").on("click", function (e) {
      e.preventDefault();

      $("#form-currency input[name='code']").val($(this).attr("href"));

      $("#form-currency").submit();
    });

    // Search
    $("#search input[name='search']")
      .parent()
      .find("button")
      .on("click", function () {
        var url =
          $("base").attr("href") +
          "index.php?route=product/search&language=" +
          $(this).attr("data-lang");

        var value = $("header #search input[name='search']").val();

        if (value) {
          url += "&search=" + encodeURIComponent(value);
        }

        location = url;
      });

    $("#search input[name='search']").on("keydown", function (e) {
      if (e.keyCode == 13) {
        $("header #search input[name='search']")
          .parent()
          .find("button")
          .trigger("click");
      }
    });

    // Menu
    $("#menu .dropdown-menu").each(function () {
      var menu = $("#menu").offset();
      var dropdown = $(this).parent().offset();

      var i =
        dropdown.left +
        $(this).outerWidth() -
        (menu.left + $("#menu").outerWidth());

      if (i > 0) {
        $(this).css("margin-left", "-" + (i + 10) + "px");
      }
    });

    // Product List
    $("#button-list").on("click", function () {
      var element = this;

      $("#product-list").attr("class", "row row-cols-1 product-list");

      $("#button-grid").removeClass("active");
      $("#button-list").addClass("active");

      localStorage.setItem("display", "list");
    });

    // Product Grid
    $("#button-grid").on("click", function () {
      var element = this;

      // What a shame bootstrap does not take into account dynamically loaded columns
      $("#product-list").attr(
        "class",
        "row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3"
      );

      $("#button-list").removeClass("active");
      $("#button-grid").addClass("active");

      localStorage.setItem("display", "grid");
    });

    // Local Storage
    if (localStorage.getItem("display") == "list") {
      $("#product-list").attr("class", "row row-cols-1 product-list");
      $("#button-list").addClass("active");
    } else {
      $("#product-list").attr(
        "class",
        "row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3"
      );
      $("#button-grid").addClass("active");
    }

    /* Agree to Terms */
    $("body").on("click", ".modal-link", function (e) {
      e.preventDefault();

      var element = this;

      $("#modal-information").remove();

      $.ajax({
        url: $(element).attr("href"),
        dataType: "html",
        success: function (html) {
          $("body").append(html);

          $("#modal-information").modal("show");
        },
      });
    });

    // Cookie Policy
    $("#cookie button").on("click", function () {
      var element = this;

      $.ajax({
        url: $(this).val(),
        type: "get",
        dataType: "json",
        beforeSend: function () {
          $(element).button("loading");
        },
        complete: function () {
          $(element).button("reset");
        },
        success: function (json) {
          if (json["success"]) {
            $("#cookie").fadeOut(400, function () {
              $("#cookie").remove();
            });
          }
        },
        error: function (xhr, ajaxOptions, thrownError) {
          console.log(
            thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText
          );
        },
      });
    });

    // Forms
    $(document).on("submit", "form", function (e) {
      var element = this;

      if (
        e.originalEvent !== undefined &&
        e.originalEvent.submitter !== undefined
      ) {
        var button = e.originalEvent.submitter;
      } else {
        var button = "";
      }

      var status = false;

      var ajax = $(element).attr("data-oc-toggle");

      if (ajax == "ajax") {
        status = true;
      }

      var ajax = $(button).attr("data-oc-toggle");

      if (ajax == "ajax") {
        status = true;
      }

      if (status) {
        e.preventDefault();

        // Form attributes
        var form = e.target;

        var action = $(form).attr("action");

        var method = $(form).attr("method");

        if (method === undefined) {
          method = "post";
        }

        var enctype = $(form).attr("enctype");

        if (enctype === undefined) {
          enctype = "application/x-www-form-urlencoded";
        }

        // Form button overrides
        var formaction = $(button).attr("formaction");

        if (formaction !== undefined) {
          action = formaction;
        }

        var formmethod = $(button).attr("formmethod");

        if (formmethod !== undefined) {
          method = formmethod;
        }

        var formenctype = $(button).attr("formenctype");

        if (formenctype !== undefined) {
          enctype = formenctype;
        }

        if (button) {
          var formaction = $(button).attr("data-type");
        }

        console.log(e);
        console.log("element " + element);
        console.log("action " + action);
        console.log("button " + button);
        console.log("formaction " + formaction);
        console.log("method " + method);
        console.log("enctype " + enctype);
        console.log($(element).serialize());

        // https://github.com/opencart/opencart/issues/9690
        if (typeof CKEDITOR != "undefined") {
          for (instance in CKEDITOR.instances) {
            CKEDITOR.instances[instance].updateElement();
          }
        }

        $.ajax({
          url: action.replaceAll("&amp;", "&"),
          type: method,
          data: $(form).serialize(),
          dataType: "json",
          contentType: enctype,
          beforeSend: function () {
            $(button).button("loading");
          },
          complete: function () {
            $(button).button("reset");
          },
          success: function (json, textStatus) {
            console.log(json);
            console.log(textStatus);

            $(".alert-dismissible").remove();
            $(element).find(".is-invalid").removeClass("is-invalid");
            $(element).find(".invalid-feedback").removeClass("d-block");

            if (json["redirect"]) {
              location = json["redirect"];
            }

            if (typeof json["error"] == "string") {
              $("#alert").prepend(
                '<div class="alert alert-danger alert-dismissible"><i class="fa-solid fa-circle-exclamation"></i> ' +
                  json["error"] +
                  ' <button type="button" class="btn-close" data-bs-dismiss="alert"></button></div>'
              );
            }

            if (typeof json["error"] == "object") {
              if (json["error"]["warning"]) {
                $("#alert").prepend(
                  '<dirv class="alert alert-danger alert-dismissible"><i class="fa-solid fa-circle-exclamation"></i> ' +
                    json["error"]["warning"] +
                    ' <button type="button" class="btn-close" data-bs-dismiss="alert"></button></dirv>'
                );
              }

              for (key in json["error"]) {
                $("#input-" + key.replaceAll("_", "-"))
                  .addClass("is-invalid")
                  .find(
                    ".form-control, .form-select, .form-check-input, .form-check-label"
                  )
                  .addClass("is-invalid");
                $("#error-" + key.replaceAll("_", "-"))
                  .html(json["error"][key])
                  .addClass("d-block");
              }
            }

            if (json["success"]) {
              $("#alert").prepend(
                '<div class="alert alert-success alert-dismissible"><i class="fa-solid fa-circle-check"></i> ' +
                  json["success"] +
                  ' <button type="button" class="btn-close" data-bs-dismiss="alert"></button></div>'
              );

              // Refresh
              var url = $(form).attr("data-oc-load");
              var target = $(form).attr("data-oc-target");

              if (url !== undefined && target !== undefined) {
                $(target).load(url);
              }
            }

            // Replace any form values that correspond to form names.
            for (key in json) {
              $(element)
                .find("[name='" + key + "']")
                .val(json[key]);
            }
          },
          error: function (xhr, ajaxOptions, thrownError) {
            console.log(
              thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText
            );
          },
        });
      }
    });

    // Upload
    $(document).on("click", "button[data-oc-toggle='upload']", function () {
      var element = this;

      if (!$(element).prop("disabled")) {
        $("#form-upload").remove();

        $("body").prepend(
          '<form enctype="multipart/form-data" id="form-upload" style="display: none;"><input type="file" name="file" value=""/></form>'
        );

        $("#form-upload input[name='file']").trigger("click");

        $("#form-upload input[name='file']").on("change", function (e) {
          if (this.files[0].size / 1024 > $(element).attr("data-oc-size-max")) {
            alert($(element).attr("data-oc-size-error"));

            $(this).val("");
          }
        });

        if (typeof timer !== "undefined") {
          clearInterval(timer);
        }

        var timer = setInterval(function () {
          if ($("#form-upload input[name='file']").val() != "") {
            clearInterval(timer);

            $.ajax({
              url: $(element).attr("data-oc-url"),
              type: "post",
              data: new FormData($("#form-upload")[0]),
              dataType: "json",
              cache: false,
              contentType: false,
              processData: false,
              beforeSend: function () {
                $(element).button("loading");
              },
              complete: function () {
                $(element).button("reset");
              },
              success: function (json) {
                console.log(json);

                if (json["error"]) {
                  alert(json["error"]);
                }

                if (json["success"]) {
                  alert(json["success"]);
                }

                if (json["code"]) {
                  $($(element).attr("data-oc-target")).attr(
                    "value",
                    json["code"]
                  );
                }
              },
              error: function (xhr, ajaxOptions, thrownError) {
                console.log(
                  thrownError +
                    "\r\n" +
                    xhr.statusText +
                    "\r\n" +
                    xhr.responseText
                );
              },
            });
          }
        }, 500);
      }
    });

    // Autocomplete
    $.fn.autocomplete = function (option) {
      return this.each(function () {
        var element = this;
        var $dropdown = $("#" + $(element).attr("data-oc-target"));

        this.timer = null;
        this.items = [];

        $.extend(this, option);

        // Focus in
        $(element).on("focusin", function () {
          element.request();
        });

        // Focus out
        $(element).on("focusout", function (e) {
          if (
            !e.relatedTarget ||
            !$(e.relatedTarget).hasClass("dropdown-item")
          ) {
            $dropdown.removeClass("show");
          }
        });

        // Input
        $(element).on("input", function (e) {
          element.request();
        });

        // Click
        $dropdown.on("click", "a", function (e) {
          e.preventDefault();

          var value = $(this).attr("href");

          if (element.items[value] !== undefined) {
            element.select(element.items[value]);

            $dropdown.removeClass("show");
          }
        });

        // Request
        this.request = function () {
          clearTimeout(this.timer);

          $("#autocomplete-loading").remove();

          $dropdown.prepend(
            '<li id="autocomplete-loading"><span class="dropdown-item text-center disabled"><i class="fa-solid fa-circle-notch fa-spin"></i></span></li>'
          );
          $dropdown.addClass("show");

          this.timer = setTimeout(
            function (object) {
              object.source($(object).val(), $.proxy(object.response, object));
            },
            50,
            this
          );
        };

        // Response
        this.response = function (json) {
          var html = "";
          var category = {};
          var name;
          var i = 0,
            j = 0;

          if (json.length) {
            for (i = 0; i < json.length; i++) {
              // update element items
              this.items[json[i]["value"]] = json[i];

              if (!json[i]["category"]) {
                // ungrouped items
                html +=
                  '<li><a href="' +
                  json[i]["value"] +
                  '" class="dropdown-item">' +
                  json[i]["label"] +
                  "</a></li>";
              } else {
                // grouped items
                name = json[i]["category"];

                if (!category[name]) {
                  category[name] = [];
                }

                category[name].push(json[i]);
              }
            }

            for (name in category) {
              html += '<li><h6 class="dropdown-header">' + name + "</h6></li>";

              for (j = 0; j < category[name].length; j++) {
                html +=
                  '<li><a href="' +
                  category[name][j]["value"] +
                  '" class="dropdown-item">' +
                  category[name][j]["label"] +
                  "</a></li>";
              }
            }
          }

          $dropdown.html(html);
        };
      });
    };

    // Button
    $.fn.button = function (state) {
      return this.each(function () {
        var element = this;

        if (state == "loading") {
          this.html = $(element).html();
          this.state = $(element).prop("disabled");

          $(element)
            .prop("disabled", true)
            .width($(element).width())
            .html(
              '<i class="fa-solid fa-circle-notch fa-spin text-light"></i>'
            );
        }

        if (state == "reset") {
          $(element).prop("disabled", this.state).width("").html(this.html);
        }
      });
    };

    $('#button-filter').on('click', function () {
      const action = $(this).data('action');
      const filter = [];
  
      $('input[name^=\'filter\']:checked').each(function (element) {
          filter.push(this.value);
      });
      
      location = `${action}&filter=${filter.join(',')}`;
    });

  };

  // Chain ajax calls.
  class Chain {
    constructor() {
      this.start = false;
      this.data = [];
    }

    attach(call) {
      this.data.push(call);

      if (!this.start) {
        this.execute();
      }
    }

    execute() {
      if (this.data.length) {
        this.start = true;

        var call = this.data.shift();

        var jqxhr = call();

        jqxhr.done(function () {
          chain.execute();
        });
      } else {
        this.start = false;
      }
    }
  }

  var chain = new Chain();

  var wishlist = {
    add: function (product_id) {
      $.ajax({
        url: "index.php?route=account/wishlist/add",
        type: "post",
        data: "product_id=" + product_id,
        dataType: "json",
        success: function (json) {
          if (json["redirect"]) {
            location = json["redirect"];
          }

          if (json["success"]) {
            notification.success(json["success"]);
          }

          $("#wishlist-total span").html(json["total"]);
        },
        error: function (xhr, ajaxOptions, thrownError) {
          notification.error(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
        },
      });
    },
    remove: function () {},
  };

  var compare = {
    add: function (product_id) {
      $.ajax({
        url: "index.php?route=product/compare/add",
        type: "post",
        data: "product_id=" + product_id,
        dataType: "json",
        success: function (json) {
          $(".alert-dismissible").remove();

          if (json["success"]) {
            notification.success(json["success"]);

          //  $("#compare-total").html(json["total"]);/
          }
        },
        error: function (xhr, ajaxOptions, thrownError) {
          notification.error(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
        },
      });
    },
    remove: function () {},
  };

  return {
    init,
    getURLVar,
    wishlist,
    compare,
    chain,
  };
})();

module.exports = Opencart;
