﻿window.$ = window.jQuery = require("jquery");
require("bootstrap");
window.notification = require("./notification");
window.common = require("./common");
window.opencart = require("./opencart");
window.popupCart = require("./popupcart");
window.checkout = require("./checkout");
window.liveSearch = require("./livesearch");
window.product = require("./product");
window.account = require("./account");

(function ($) {
  window.notification.init();
  window.common.init();
  window.opencart.init();
  window.popupCart.init();
  window.checkout.init();
  window.liveSearch.init();
  window.product.init();
  window.account.init();

  // Added global $ for console debug
  window.$ = $;

  const moduleElements = document.querySelectorAll("[data-module]");

  for (let i = 0; i < moduleElements.length; i++) {
    const el = moduleElements[i];
    const name = el.getAttribute("data-module");

    const Module = require("./" + name);
    new Module(el);
  }
})(jQuery);
