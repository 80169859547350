﻿const { Swiper } = require('swiper');
const { Autoplay, Pagination } = require('swiper/modules');

const testimonialSlider = function (el) {
	Swiper.use([Pagination, Autoplay]);

	const swiper = new Swiper(el, {
		loop: true,
		pagination: {
			el: ".swiper-pagination",
			clickable: true,
		},
		autoplay: {
			delay: 5000
		},
		speed: 400,
		slidesPerView: 1,
	});
};

module.exports = testimonialSlider;