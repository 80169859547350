"use strict";
const account = (function () {
  const load = () => {
    $("input[name='action']").on("change", function () {
      if (this.value == "remove") {
        $("#collapse-remove").slideDown();
      } else {
        $("#collapse-remove").slideUp();
      }
    });

    // Sort the custom fields
    $(".form-group[data-sort]")
      .detach()
      .each(function () {
        if (
          $(this).attr("data-sort") >= 0 &&
          $(this).attr("data-sort") <= $(".form-group").length
        ) {
          $(".form-group").eq($(this).attr("data-sort")).before(this);
        }

        if ($(this).attr("data-sort") > $(".form-group").length) {
          $(".form-group:last").after(this);
        }

        if ($(this).attr("data-sort") == $(".form-group").length) {
          $(".form-group:last").after(this);
        }

        if ($(this).attr("data-sort") < -$(".form-group").length) {
          $(".form-group:first").before(this);
        }
      });

    $("input[name='customer_group_id']").on("change", function () {
      $.ajax({
        url:
          "index.php?route=account/register/customfield&customer_group_id=" +
          this.value,
        dataType: "json",
        success: function (json) {
          $(".custom-field").hide();
          $(".custom-field").removeClass("required");

          for (i = 0; i < json.length; i++) {
            custom_field = json[i];

            $("#custom-field" + custom_field["custom_field_id"]).show();

            if (custom_field["required"]) {
              $("#custom-field" + custom_field["custom_field_id"]).addClass(
                "required"
              );
            }
          }
        },
        error: function (xhr, ajaxOptions, thrownError) {
          alert(
            thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText
          );
        },
      });
    });

    $("input[name='customer_group_id']:checked").trigger("change");

    $("button[id^='button-custom-field']").on("click", function () {
      var element = this;

      $("#form-upload").remove();

      $("body").prepend(
        '<form enctype="multipart/form-data" id="form-upload" style="display: none;"><input type="file" name="file" /></form>'
      );

      $("#form-upload input[name='file']").trigger("click");

      if (typeof timer != "undefined") {
        clearInterval(timer);
      }

      const timer = setInterval(function () {
        if ($("#form-upload input[name='file']").val() != "") {
          clearInterval(timer);

          $.ajax({
            url: "index.php?route=tool/upload",
            type: "post",
            dataType: "json",
            data: new FormData($("#form-upload")[0]),
            cache: false,
            contentType: false,
            processData: false,
            beforeSend: function () {
              $(element).button("loading");
            },
            complete: function () {
              $(element).button("reset");
            },
            success: function (json) {
              $(element).parent().find(".text-danger").remove();

              if (json["error"]) {
                $(node)
                  .parent()
                  .find("input")
                  .after(
                    '<div class="text-danger">' + json["error"] + "</div>"
                  );
              }

              if (json["success"]) {
                alert(json["success"]);

                $(element).parent().find("input").val(json["code"]);
              }
            },
            error: function (xhr, ajaxOptions, thrownError) {
              alert(
                thrownError +
                  "\r\n" +
                  xhr.statusText +
                  "\r\n" +
                  xhr.responseText
              );
            },
          });
        }
      }, 500);
    });

    $("input[name='payment']").on("change", function () {
      $(".payment").hide();

      $("#payment-" + this.value).show();
    });

    $("input[name='payment']:checked").trigger("change");

    $("select[name='country_id']").on("change", function () {
      $.ajax({
        url: "index.php?route=account/account/country&country_id=" + this.value,
        dataType: "json",
        beforeSend: function () {
          $("select[name='country_id']").prop("disabled", true);
        },
        complete: function () {
          $("select[name='country_id']").prop("disabled", false);
        },
        success: function (json) {
          if (json["postcode_required"] == "1") {
            $("input[name='postcode']").parent().parent().addClass("required");
          } else {
            $("input[name='postcode']")
              .parent()
              .parent()
              .removeClass("required");
          }

          html = '<option value="">{{ text_select }}</option>';

          if (json["zone"] && json["zone"] != "") {
            for (i = 0; i < json["zone"].length; i++) {
              html += '<option value="' + json["zone"][i]["zone_id"] + '"';

              if (json["zone"][i]["zone_id"] == "{{ zone_id }}") {
                html += ' selected="selected"';
              }

              html += ">" + json["zone"][i]["name"] + "</option>";
            }
          } else {
            html +=
              '<option value="0" selected="selected">{{ text_none }}</option>';
          }

          $("select[name='zone_id']").html(html);
        },
        error: function (xhr, ajaxOptions, thrownError) {
          alert(
            thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText
          );
        },
      });
    });

    $("select[name='country_id']").trigger("change");
  };

  return {
    init: function () {
      load();
    },
  };
})();

module.exports = account;
